import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

const routes: Routes = [
  { path: '', redirectTo: 'inicial', pathMatch: 'full' },
  { path: 'home', loadChildren: './home/home.module#HomePageModule' },
  { path: 'agradecimento', loadChildren: './agradecimento/agradecimento.module#AgradecimentoPageModule' },
  { path: 'relatorios', loadChildren: './relatorios/relatorios.module#RelatoriosPageModule' },
  { path: 'inicial', loadChildren: './inicial/inicial.module#InicialPageModule' },
  { path: 'widget', loadChildren: './widget/widget.module#WidgetPageModule' },
  { path: 'cadastroGrupo', loadChildren: './cadastro-grupos/cadastro-grupos.module#CadastroGrupoPageModule' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {useHash: true})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
