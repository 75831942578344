import { AngularFirestore } from 'angularfire2/firestore';
import { Injectable } from '@angular/core';

@Injectable()
export class GrupoQuery {
  grupoCollection = this.db.collection<any>(`grupos`)

  constructor(private db: AngularFirestore) { }

  async getGrupos() {
    return await this.grupoCollection.snapshotChanges();
  }
}