import { AngularFirestore } from 'angularfire2/firestore';
import { Injectable } from '@angular/core';

@Injectable()
export class NPSSoa {
  npsCollection = this.db.collection<any>(`nps`)
  constructor(private db: AngularFirestore) {
  }
  
  async adiciona(nota: number, comentario: string, grupo: string, local: string) {
    let dados = {
      nota,
      comentario,
      local,
      grupo,
      date: new Date()
    }
    return await this.npsCollection.add(dados)
  }
}