import { AngularFirestore } from 'angularfire2/firestore';
import { Injectable } from '@angular/core';

@Injectable()
export class NPSQuery {
  npsCollection = this.db.collection<any>(`nps`)
  constructor(private db: AngularFirestore) {
  }
  async getNPS() {
    return await this.npsCollection.snapshotChanges();
  }
}