import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GrupoCadastroSoa } from './grupoCadastro/grupoCadastro.soa';
import { NPSSoa } from './nps/nps.soa';


@NgModule({
  imports: [
    CommonModule
  ],
  declarations: [
  ],
  providers: [
    NPSSoa,
    GrupoCadastroSoa
  ]
})
export class SOAModule { }