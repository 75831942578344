import { AngularFirestore } from 'angularfire2/firestore';
import { Injectable } from '@angular/core';

@Injectable()
export class GrupoCadastroSoa {
  grupoCadastroCollection = this.db.collection<any>(`grupos`)
  constructor(private db: AngularFirestore) {
  }
  
  async adiciona(nomeGrupo: string) {
    let dados = {
      nomeGrupo,
      date: new Date()
    }
    return await this.grupoCadastroCollection.add(dados)
  }
  
  async remove(idGrupo: string) {
    return await this.grupoCadastroCollection.doc(idGrupo).delete();
  }

}